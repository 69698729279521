.resetform label {
	font-weight: 600;
}
.logo{
  width: 90%;
  /* margin-bottom: 25px; */
}

input,
input::placeholder {
    font: 15px/3 'Inter', sans-serif;
}

.cont{
  height: 100vh;
  
  /* display: flex !important; 
  justify-content: center !important;
  align-items: center !important;
  width: 800px;
  margin: auto; */
}

.main-container{
  box-shadow: 0 1px 8px rgba(17, 17, 17, 0.25);
  border-radius: 8px;
}

.submitbtn{
  display: flex;
  
}

@media (min-width: 320px) and (max-width: 767px){
  .cont{
    width: 85%;
   margin-left:1.8rem;
  }
} 
/* .main-cls1{
  margin-top: 5rem;
} */


@media (min-width: 320px) and (max-width: 767px){
  .logo{
    width:  60%;
    margin-bottom: 25px;
    
  }
}