.MuiInputBase-root {
	margin-top: -15px !important;
}

.MuiTablePagination-actions {
	margin-top: -15px !important;
}

.deleteIconClass {
	margin-right: 10px !important;
	/* font-size: 18px !important; */
	height: 18px !important;
	color: red;
}

.sendIconClass {
	margin-right: 10px !important;
	color: #2155cd;
	/* font-size: 18px !important; */
	height: 18px !important;
}

.editIconClass {
	margin-right: 10px !important;
	color: #36ae7c;
	/* font-size: 18px !important; */
	height: 18px !important;
}
.search-container {
	/* background-color: red; */
	/* position: relative; */
	display: flex;
}

.search-icon-inner-container {
	/* background-color: green; */
	position: relative;
}
.search-icon {
	position: absolute;
	right: 10px;
	top: 6px;
}

.input-box {
	background-color: transparent;
	/* border:none; */

	outline: none;
	border: 1px solid grey;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	padding-left: 8px;
	padding-right: 30px;
}

/* Date picker icon css */

.react-datepicker__navigation-icon--next::before{
	left: 3px;
    top: 13px;
}

.react-datepicker__navigation-icon--previous::before{
	right: 3px;
    top: 13px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	top:1px
}

.react-datepicker__month-text--keyboard-selected{
 font-weight: 400;
 background: none;
}

.select-filter-year:hover{
   
}

