.header{
    font-weight: 700;
}
.headertext{
    font-weight: 400;
}

.form label {
	font-weight: 600;
}

.updateBtn {
	position: absolute;
	top: 1.3rem;
	right: 2rem;
	color: #2155cd;
	font-size: 0.8rem;
	font-weight: 600;
	background-color: transparent;
}
.updateBtn:hover {
	cursor: pointer;
}

.sign-out-btn span:hover{
cursor: pointer;
}


input,
input::placeholder {
    font: 15px/3 'Inter', sans-serif;
}

.flex-wrapper-myaccount{
    display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.my_account_password{
	position: relative !important;
	  }
	
	  .my_account_eye_icon{
		
	position: absolute !important;
	/* margin-left: 436px;
		margin-top: 10px; */
		right:20px;
		top:16px;
	  }

	  .newpassword_eye_icon{
		position: absolute !important;
		right:20px;
		top:16px;
	  }

	  .my_account_new_password{
		position: relative !important;
	  }