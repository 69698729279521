.primarybutton {
  background-color: #2155cd;
  color: #ffffff;
  padding: 0px 15px 0px 15px !important;
}

/* checks */
.container h1 {
  font-weight: 700;
}

.seed {
  color: red;
}
.signIn {
  /* min-height: 100vh; */
}
.flex-wrapper-signin {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}


.visibleIcon i{
position:absolute;
 left: 87%; 
 margin-top:15px
}

.icon4{
  position: relative;
}

@media screen and (min-width:768px) and (max-width:987px){
  .forgotpassword{
    font-size:0.8rem;
  }
}
