.settings label {
  font-size: 0.8rem;
  color: #888888;
}

.form-control [type=number]{
  -moz-appearance: textfield;
}

.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.info-lables {
  font-size: 16px;
  color: #888888;
}

.settings input {
  background-color: #dddddd1a;
}

.settings select {
  background-color: #dddddd1a;
}

.settings select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.settings input::placeholder {
  color: #dddddd;
}

.settings select::placeholder {
  color: #dddddd;
}

.Modal-close-btn {
  border: none;
  color: #2155cd;
  font-size: 0.7rem;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Modal-close-btn:hover {
  border-color: #ffffff;
}

.modal-company-id {
  color: #36ae7c;
}

.edit-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.flop {
  flex: 1;
  font-size: 30px;
}

.flo {
  float: right !important;
  margin-right: 1rem;
  border-radius: 50%;
  color: #dddddd;
  cursor: pointer;
}

.setting_logoURl {
  border: 1px solid #dddddd;
  display: flex;
  justify-content: start;
  align-items: center;
  /* height: 100%; */
  width: 100%;
  min-height: 44px;
  border-radius: 4px;
  opacity: 1;
  position: relative;
}

.setting_logoURl label input[type="file"] {
  display: none;
}

.setting_logoURl label {
  width: 100%;
  height: 100%;
}

.setting_logoURl input {
  width: 100%;
  height: 100%;
}

.org-description {
  margin-bottom: 8px;
}

.logo_container label input[type="file"] {
  display: none;
}

.logo_container {
  border: 1px solid #dddddd;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 7.5rem;
  width: 100%;
  border-radius: 4px;
  opacity: 1;
  position: relative;
}

.logo_container img {
  margin-left: 16px;
}

.logo_container label {
  width: 100%;
}

.logo_container label div {
  height: 2.125rem;
  width: 9.25rem;
  text-align: left;
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  margin-left: 16px;
}

.logo_container label input {
  width: 100%;
}

.logo_container label div p {
  font-size: 12px;
}

.logo-section {
  margin-top: 11px !important;
}

@media (max-width: 426px) {
  .logo-url-link {
    font-size: 9px;
  }
}

@media (max-width: 769px) {
  .logo-url-link {
    font-size: 11px !important;
  }
}

@media (max-width: 1025px) {
  .logo-url-link {
    font-size: 13px !important;
  }
}

@media (min-width: 1441px) {
  .logo-url-link {
    font-size: 14px !important;
  }
}

@media (min-width: 2561px) {
  .logo-url-link {
    font-size: 14px !important;
  }
}

@media (max-width: 500px) {
  .flo {
    padding: 0.3rem;
  }
}

.flex-wrapper-settings {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.settings input::-webkit-outer-spin-button,
.settings input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}