.LogoImgs{
  width: 25%;
  margin-bottom: 25px;
}

.reset-Container{
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(17, 17, 17, 0.25);
  width: 100%
}

.resetbutton{
  background-color: #2155cd;
  color: #ffffff;
}

.error{
  color: red;
}

@media (min-width: 320px) and (max-width: 767px){
  .LogoImgs{
    width:  60%;
    margin-bottom: 25px;
    
  }
}