.message_input {
    width: 100%;
    min-height: 140px !important;
}

.message_input:focus {
    box-shadow: none !important;
}

.lables>label {
    color: #808080;
    margin-top: 12px;
}

.contact-submit-form {
    padding-top: 9px;
    padding-right: 36px;
    padding-left: 36px;
    padding-bottom: 9px;
    background-color: rgb(33, 85, 205);
    border: rgb(33, 85, 205);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    margin-top: 10px;
}

@media (min-width:768px) {
    .box-shadow {
        border-radius: 10px;
        padding-top: 0px !important;
        padding-bottom: 50px !important;
        box-shadow: 5px 10px 18px #888888;
    }
}