.metacontent {
  width: 100% !important;
}

.activetab {
  background-color: #95bcf0;
  color: white;
}

.unActiveTab {
  border: 1px solid #95bcf0;
  color: #95bcf0;
  /* color:red;
    background-color: blue; */
}

.seo_image_upload input[type="file"] {
  display: none;
}

.seo_image_upload input {
  width: 100%;
  height: 100%;
}

.seo_image_upload {
  border: 1px solid #ced4da;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 44px;
  border-radius: 6px;
  background-color: #efefef;
}

.seo_image_upload label {
  width: 100%;
  height: 100%;
}

.seomaindiv {
  padding-top: 75px;
  position: relative;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.seo_container {
  box-shadow: 0px 0px 15px rgb(0 0 0 / 0.4);
  border-radius: 12px;
  width: 800px;
  height: 55%;
  padding: 20px;
}

.seo_container_info {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: start;
  position: relative;
}

.seotr {
  border-top: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  background-color: #f3f3f3;
}

/* .react-tabs__tab-list{
    border-bottom: initial !important;
}
.react-tabs__tab{
    border: 1px solid black !important; 
    position: initial !important;
} */

.react-tabs__tab:focus {
  /* border: 1; */
  /* outline: 1px solid red !important; */
}
.pagenametext {
  resize: none;
  background-color: white;
  color: black;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.metcontent {
  resize: none;
  background-color: white;
  color: black;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-top: 10px;
  padding-left: 10px;
}

.seoupdatebtn {
  position: relative;
}

@media (max-width: 769px) {
  .metacontent {
    width: 100% !important;
  }

  .seotable {
    width: 100% !important;
  }
}

@media (max-width: 426px) {
  .metacontent {
    width: 600px !important;
  }

  .seotable {
    width: 120vh !important;
  }

  .seomaindiv {
    margin-top: 120px !important;
  }
}

@media (max-width: 376px) {
  .metacontent {
    width: 600px !important;
  }

  .seotable {
    width: 130vh !important;
  }

  .seomaindiv {
    margin-top: 115px !important;
  }

  .seomaindiv {
    margin-top: 120px !important;
  }
}

@media (max-width: 320px) {
  .metacontent {
    width: 600px !important;
  }

  .seotable {
    width: 140vh !important;
  }

  .seomaindiv {
    margin-top: 120px !important;
  }
}
