body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Playfair Display', serif;
    font-family: 'Square Peg', cursive;
    font-family: 'Manrope', sans-serif;
    font-family: 'Merriweather', serif;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
