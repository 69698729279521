.color_main_container p {
  margin: 0px;
  margin-bottom: 4px;
  margin-top: 8px;
  text-align: left;
  font: normal normal normal 14px/15px Inter;
  letter-spacing: 0px;
  color: #251d3a;
  opacity: 1;

}

.color_main_container .colorBoxContainer {
  display: flex;
  flex-wrap: wrap !important;
  width: 100%;
  align-items: center;
  margin-left: 5px;
  padding: 4px  8px !important;
  border: 1px solid #dddddd;
  background-color: white;
  border-radius: 4px;
  /* height: 60px;        */
  /* padding-right:3px !important;  */
  justify-content:center !important;
  justify-content:space-between !important; 
}

.color_main_container .colorContainer{
  border:1px solid white;
  border-radius: 4px;
  /* padding-top: 1.8px; 
  padding-left: 3.5px !important;
 padding-bottom: 2px;
  margin-right: 3px; */
  cursor: pointer!important;
}


.color_main_container .colorContainer:hover {
  border: 1px solid rgb(102, 144, 217);
  border-radius: 4px;
  /* padding-top: 1.8px; 
  padding-left: 3.5px !important;
 padding-bottom: 2px;
  margin-right: 3px; */
  cursor: pointer!important;
}

.color_main_container .colorBox {
  height: 20px;
  width: 18px;
  border-radius: 4px;
  margin: 2px;
}

.color_main_container input{
  cursor: pointer;
}


@media (min-width: 768px) and (max-width:1200px)
{
.color_main_container .colorBoxContainer{
  height: 75px !important; 
  } 
}