.backgrounds {
	background-color: #f2f2f2 !important;
	padding-top: 2.7rem;
}

.error {
	color: red;
	font-size: 10px;
	margin-left: 6rem;
}

.header_container label input[type="file"] {
	display: none;
}

.header_container {
	border: 1px dashed #dddddd;
	display: flex;
	justify-content: start;
	align-items: center;
	height: 64px;
	width: 100%;
	max-width: 23.375rem;
	border-radius: 4px;
	opacity: 1;
	position: relative;
}

.header_container img {
	padding-left: 16px;
}

.header_container label {
	width: 100%;
}

.header_container label div {
	height: 2.125rem;
	width: 9.25rem;
	text-align: left;
	font: normal normal normal 14px/17px Inter;
	letter-spacing: 0px;
	color: #888888;
	opacity: 1;
	margin-left: 16px;
}

.header_container label input {
	width: 100%;
}

.header_container label div p {
	font-size: 12px;
}

.containerOne_info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 82px;
	height: 40px;
	max-width: 23.375rem;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
}

.containerOne_info label {
	padding-left: 16px;
	text-align: left;
	font: normal normal normal 12px/15px Inter;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

.containerOne_info input {
	height: 45px;
	padding-left: 16px;
	width: 100%;
	text-align: left;
	font: normal normal normal 14px/17px Inter;
	letter-spacing: 0px;
	color: #888888;
	opacity: 1;
	border: none;
	outline: none;
	/* background-color: #2155cd; */
}

.containerOne {
	display: flex;
	flex-direction: column;
}

.containerOne_input_one {
	padding-left: 16px;
	height: 84px;
	width: 100%;
	max-width: 23.375rem;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	outline: none;
}

.containerOne_input {
	padding-left: 16px;
	min-height: 84px;
	width: 100%;
	max-width: 23.375rem;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	outline: none;
	overflow: hidden;
}

.containerTwo_input {
	padding-top: 5px;
	padding-left: 16px;
	min-height: 31px;
	width: 100%;
	max-width: 23.375rem;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	outline: none;
	overflow: hidden;
}

.invoice_title {
	/* height: 100%; */
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 14px;
	height: 56px;
}

.invoice_title input:hover {
	border: 1px solid #dddddd;
	border-radius: 4px;
	opacity: 1;
	text-align: end;
}

.invoice_title input {
	height: 100%;
	text-align: end;
	width: 100%;
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0px;
	/* color: #000000; */
	border: none;
	cursor: pointer;
}

.containerTwo
	.containerTwo_inputTwo
	input[type="date"]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

.containerTwo {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}

.containerTwo .containerTwo_inputOne {
	width: 100%;
}

.containerTwo .containerTwo_inputOne input {
	border: none;
	outline: none;
	width: 90%;
	height: 46px;
	color: #888888;
	text-align: end;
	padding-right: 10px;
}

.containerTwo .containerTwo_inputOne input:hover {
	border-radius: 4px;
	border: 1px solid #dddddd;
	cursor: pointer;
}

.containerTwo .containerTwo_inputTwo {
	width: 100%;
	position: relative;
	display: flex;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	padding-right: 10px;
}

.containerTwo .containerTwo_inputTwo input {
	width: 100%;
	text-align: end;
	height: 46px;
	/* text-align: start; */
	padding-left: 10px;
	border: none;
	color: #888888;
}

.containerTwo .containerTwo_inputTwo span {
	height: 46px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f2f2f2;
}

.poNumber .poNumber_inputTwo input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* .invoiceTable table {
  width: 100%;
  margin-bottom: 12px;
}

.invoiceTable table tbody {
  margin-top: 12px;
}

.invoiceTable table thead tr {
  width: 100%;
  border-radius: 5px !important;
  background-color: #f2f2f2;
  color: #000000;
}

.invoiceTable table thead tr td input {
  border: none;
  height: 100%;
}

.invoiceTable table tbody tr td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invoiceTable table tbody tr td input {
  padding-left: 5px;
  width: 100%;
  border: 1px dashed #dddddd;
  outline: none;
  height: 56px;
} */

/* .amount {
  border: 1px dashed #dddddd;
  width: 100%;
  text-align: right;
}

.amount p {
  margin: 8px;
  font-size: 12px;
  text-align: center;
} */

/* .invoiceTable table thead tr td {
  height: 56px;
  text-align: left;
} */

/* .delte span img {
  padding-left: 14px;
  cursor: pointer;
} */

.btnOne button {
	border: none;
	height: 35px;
	background: #2155cd08 0% 0% no-repeat padding-box;
	border: 1px solid #2155cd;
	border-radius: 4px !important;
	opacity: 1;
}

.btnOne {
	margin-bottom: 18px;
}

.btnOne button img {
	padding-left: 10px;
}

.btnOne button span {
	text-align: left;
	font: normal normal normal 14px/17px Inter;
	letter-spacing: 0px;
	color: #2155cd;
	opacity: 1;
	margin-left: 7px;
	padding-right: 10px;
}

.Term_and_condition {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 24px;

	height: 5.625rem;
	width: 100%;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	margin-bottom: 12px;
}

.Term_and_condition input {
	text-align: left;
	font: normal normal normal 14px/17px Inter;
	letter-spacing: 0px;
	color: #888888;
	opacity: 1;
	outline: none;
	border: none;
}

.Term_and_condition label {
	text-align: left;
	font: normal normal normal 12px/15px Inter;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

.notes {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 24px;
	height: 5.625rem;
	width: 100%;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	margin-bottom: 12px;
}

.notes input {
	text-align: left;
	font: normal normal normal 14px/17px Inter;
	letter-spacing: 0px;
	color: #888888;
	opacity: 1;
	outline: none;
	border: none;
}

.notes label {
	text-align: left;
	font: normal normal normal 12px/15px Inter;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

.invoiceSummary {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f2f2f2;
	height: 56px;
	margin-bottom: 12px;
}

.discount_shipping {
	text-align: right;
	font: normal normal normal 14px/17px Inter;
	letter-spacing: 0px;
	/* color: #2155cd; */
	opacity: 1;
	padding: 12px 0px;
}

.discount_shipping span {
	margin-right: 18px;
	width: 71px;
	height: 17px;
}

/* .discount_shipping input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
.discount_shipping input {
	height: 48px;
	/* width: 13.75rem; */
	width: 12.75rem;
	max-width: 70%;
	border: 1px solid #dddddd;
	border-radius: 4px;
	opacity: 1;
	margin-bottom: 12px;
	outline: none;
	text-align: right;
	/* margin-right:20px; */
}

.invoiceSummaryOne {
	align-items: center;
	color: #888888;
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 15px;
}

.invoiceSummaryOne .subtotal_left_inputOne {
	display: flex;
	width: 100%;
}

.invoiceSummaryOne .subtotal_left_inputOne input {
	border: none;
	outline: none;
	width: 90%;
	color: #888888;
	height: 48px;
	opacity: 1;
	text-align: end;
	padding-right: 10px;
}

.invoiceSummaryOne .subtotal_left_inputOne input:hover {
	border-radius: 4px;
	border: 1px solid #dddddd;
	cursor: pointer;
}

.invoiceSummaryOne .subtotal_left_inputTwo {
	display: flex;
	justify-content: end;
	align-items: center;
	width: 90%;
	margin-right: 38px;
	height: 48px;
}

.amountPaid {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}

.amountPaid .amountPaid_inputOne {
	width: 100%;
}

.amountPaid .amountPaid_inputTwo {
	width: 100%;
}

.amountPaid .amountPaid_inputOne input {
	border: none;
	outline: none;
	width: 90%;
	height: 48px;
	text-align: end;
	padding-right: 10px;
	color: #888888;
}

.amountPaid .amountPaid_inputOne input:hover {
	border-radius: 4px;
	border: 1px solid #dddddd;
	cursor: pointer;
}

.amountPaid .amountPaid_inputTwo input {
	width: 90%;
	height: 48px;
	text-align: end;
	border: 1px solid #dddddd;
	border-radius: 4px;
	opacity: 1;
	color: #888888;
	padding-right: 15px;
}

.amountPaid .amountPaid_inputTwo input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Download side css */

.buttonOne {
	width: 100%;
	height: 3rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	color: #251d3a;
	border: none;
}

.disable button {
	width: 100%;
	height: 3rem;
	background-color: #888888;
	border: 1px solid #888888;
	border-radius: 4px;
	opacity: 1;
	color: #ffffff;
	font-size: 0.88rem;
}

.buttonTwo button {
	width: 100%;
	height: 3rem;
	background: #2155cd 0% 0% no-repeat padding-box;
	border: 1px solid #2155cd;
	border-radius: 4px;
	opacity: 1;
	color: #ffffff;
	font-size: 0.88rem;
}

.buttonTwo button img {
	padding-right: 5px;
	margin-top: -3px;
}

.disable button img {
	padding-right: 5px;
	margin-top: -3px;
}

.select_container p {
	margin: 0px;
	margin-bottom: 4px;
	text-align: left;
	font: normal normal normal 14px/15px Inter;
	letter-spacing: 0px;
	color: #251d3a;
	opacity: 1;
}

.select_container select {
	width: 100%;
	height: 3rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dddddd;
	border-radius: 4px;
	opacity: 1;
	outline: none;
}

.select_container option {
	font-size: 16px;
}

.select_container {
	margin-top: 1rem;
}

/* PDF CSS */

.App {
	text-align: center;
}

.borderless td,
.borderless th {
	border: none;
}

.discount_left {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #888888;
	position: relative;
	/* border: 1px solid red; */
	/* margin-bottom: 30px; */
	/* margin-top:10px; */
	/* width: 90%; */
}

.discount_left .discount_left_inputWrap {
	display: flex;
	width: 95%;
	justify-content: space-between;
	margin-bottom: 15px;
}

.discount_left .discount_left_inputWrap .discount_left_inputOne {
	display: flex;
	width: 100%;
}

.discount_left .discount_left_inputWrap .discount_left_inputOne input {
	/* display: flex; */
	border: none;
	outline: none;
	width: 90%;
	color: #888888;
	height: 48px;
	text-align: end;
	padding-right: 10px;
	opacity: 1;
}

.discount_left .discount_left_inputWrap .discount_left_inputOne input:hover {
	border: 1px solid #dddddd;
	border-radius: 4px;
	cursor: pointer;
}

.discount_left_inputTwo {
	position: relative;
}

.discount_left .discount_left_inputWrap .discount_left_inputTwo {
	width: 100%;
	display: flex;
}

.discount_left .discount_left_inputWrap .discount_left_inputTwo input {
	width: 100%;
	color: #888888;
	height: 48px;
	border: 1px solid #dddddd;
	border-radius: 4px;
	opacity: 1;
	padding-right: 38px;
}

.discount_left_inputTwo .symbol {
	position: absolute;
	top: 16px;
	right: 80%;
	/* font-size: 12px; */
	/* margin-right: 170px; */
}

.discount_left_inputTwo .taxPercent {
	position: absolute;
	/* background-color: aquamarine; */
	top: 16px;
	right: 23px;
	/* padding-right: -15rem; */
}

.showdisplay {
	position: relative;
}

.showdisplay span {
	position: absolute;
	top: 11px;
	right: 28px;
	cursor: pointer;
}

.showdisplay span img:hover {
	color: rgb(26, 241, 62);
}

.showdisplay:hover .displaynone {
	display: block;
}

.displaynone:hover {
	color: #2155cd;
}

.displaynone {
	display: none;
}

.discount_left input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* .cross-symbol-container {
  background-color: red;
  height: 10px;
  width: 120px;
  cursor: pointer;
  position: relative;
}

.cross-symbol {
  position: absolute;
  right: 0;
  margin-top: 15px;
  left: 10;
  cursor: pointer;
} */

.rate span {
	position: absolute;
	left: 0;
	bottom: "15px";
	padding-left: "8px";
	margin-top: "40px";
	top: 20px;
	/* right: 0; */
}

.rate input {
	text-align: center;
}

.country__symbol {
	position: absolute;
	right: 100px;
	border: none;
	color: black;
	text-align: left;
}

.invoicePdfButton {
	width: 100%;
	cursor: pointer;
}

.invoicePdfButton div {
	margin: 10px;
}

.iconAndTextContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1.4px solid rgb(231, 229, 229);
	background-color: white;
	margin-top: 0;
	border-radius: 2px;
}

.iconAndTextContainer div {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: center;
}

.iconAndTextContainer .icon-container {
	display: flex;
	justify-content: center;
}

.myIconContainer {
	width: 100%;
}

.myIconContainer img {
	height: 20px;
}

.pdfTextContainer {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.pdfTextContainer p {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 0px;
	color: rgb(59, 59, 216);
}

.pdfTextContainer1 {
	padding: 12px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.pdfTextContainer1 p {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 0px;
	color: rgb(59, 59, 216);
}

.fileFormatHeading h4 {
	font-size: 15px;
	color: black;
	font-weight: 400;
}

.select_container select {
	font-size: 12px;
}

.select_container select option {
	font-size: 12px;
}

.containerOne_info input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.invoice_title input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.notes input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.Term_and_condition input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.invoiceSummaryOne input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.inputDecoration input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.discount_left_inputOne input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.amountPaid_inputOne input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.containerTwo_inputOne input:focus {
	border: 1px solid #dddddd !important;
	border-radius: 4px;
}

.flex_wrapper_invoicedowloader {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}

.invisibleInput {
	/* width:100%;
  height:100%; */
	/* position: absolute !important; */
	height: 0%;
	width: 0%;
	opacity: 0;
	/* padding: 0px !important; */
	/* margin: 0px !important; */
	/* z-index: 0; */
}

.react-datepicker-popper {
	/* top :-830px !important;
left :870px !important; */

	inset: -8px auto auto -153px !important;
}

.react-datepicker__tab-loop {
	position: relative !important;
	/* left: 847px !important;
top: 600px !important */
}

.react-datepicker-wrapper {
	width: 0% !important;
	height: 0% !important;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::after {
	border-width: 0px !important;
}

.containerTwo .containerTwo_inputTwodate {
	width: 100%;
	height: 46px;
	position: relative;
	display: flex;
	border: 1px dashed #dddddd;
	border-radius: 4px;
	opacity: 1;
	padding-right: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
	/* padding-top: 15px; */
	/* z-index: 1; */
	padding-bottom: -25px;
}

.containerTwo .containerTwo_inputTwodate .date_input_first {
	border: none;
	width: 100%;
	height: 100%;
	color: #888888;
	text-align: end;
	padding-left: 10px;
	/* z-index:1; */
}

.refresh {
	font-size: 18px;
	/* padding-right: 8px; */
}

.refresh:hover {
	color: #2155cd;
}

.row .history-btn:hover {
	background-color: #2155cd;
	color: white;
}

.row .history-btn {
	width: 100%;
	font-size: 1.2rem;
	/* border:1px solid black; */
	/* border:non; */
	border-radius: 5px;
	height: 3rem;
	/* padding-right:8rem; */
	/* margin-bottom:5px */
}

.imagecrossbtn {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 10px;
	background-color: #000000;
	color: white;
	padding: 4px;
	border: none;
	border-radius: 5px;
}

.imagecrossbtn:hover {
	background-color: rgb(239, 32, 32);
}

.history-notification-design {
	border-radius: 100%;
	font-size: 15px;
	color: #f1f4f8;
	padding: 5px;
	font-weight: bold;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #2155cd;
}

.history-btn:hover .history-notification-design {
	background-color: white !important;
	color: #2155cd !important;
	/* border: 1px solid #95bcf0 !important; */
}

@media (min-width: 320px) and (max-width: 540px) {
	.refresh {
		position: absolute;
		left: -12px;
		top: 5px;
	}

	.discount_left_inputTwo .symbol {
		font-size: 12px;
		position: absolute;
		top: 14px;
		right: 75%;
	}

	.subtotal_left_inputTwo span {
		font-size: 12px;
	}

	.discount_left_inputTwo input {
		font-size: 12px;
	}

	.discount_left_inputTwo .taxPercent {
		font-size: 12px;
		position: absolute;
		top: 16px;
		right: 23px;
	}

	.amountPaid_inputTwo input {
		font-size: 12px;
	}

	.amountPaid_inputTwo span {
		font-size: 12px;
		padding-top: 5px;
	}
}

@media screen and (min-width: 540px) {
	.refresh {
		position: absolute;
		left: -16px;
		top: 5px;
	}
}

@media screen and (max-width: 766px) {
	.rate span {
		position: absolute;
		left: 3px;
		margin-left: 10px;
		margin-bottom: 40px;
		top: 10px;

		right: 0;
		font-size: 11px;
	}

	.rate input {
		text-align: center;
		font-size: 11px;
	}

	.amount p {
		text-align: center;
		margin-top: 8px;
	}

	.quantityInput Input {
		font-size: 11px;
	}
}

@media screen and (max-width: 412px) {
	.country__symbol {
		right: 50px;
	}
}

@media screen and (min-width: 768px) {
	.rate {
		margin-top: 0px;
		font-size: 12px;
	}

	.rate span {
		position: absolute;
		left: 25px;
		top: 10px;
	}

	.rate input {
		text-align: center;
	}

	.amount p {
		font-size: 12px;
		text-align: center;
		margin-top: 10px;
	}
}

@media screen and (min-width: 768px) and (max-width: 993px) {
	.refresh {
		position: absolute;
		left: -5px;
		top: 6.5px;
	}

	.discount_left_inputTwo .symbol {
		font-size: 12px;
		position: absolute;
		top: 16px;
		right: 72%;
	}

	.subtotal_left_inputTwo span {
		font-size: 12px;
	}

	.amountPaid_inputTwo span {
		font-size: 12px;
		position: absolute;
		left: 7px !important;
		top: 14px !important;
	}

	.amountPaid_inputTwo input {
		font-size: 12px;
	}

	.discount_left_inputTwo input {
		font-size: 12px;
	}

	.discount_left_inputTwo .taxPercent {
		font-size: 12px;
		position: absolute;
		top: 16px;
		right: 23px;
	}

	.refresh {
		font-size: 15px;
	}
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
	.rate span {
		position: absolute;
		/* left: 3px; */
		/* margin-left: 4px; */
		/* margin-bottom: 40px; */
		/* top: 22px; */
		font-size: 14px;
		right: 0;
		font-size: 11px;
		left: 39px;
	}

	.rate input {
		text-align: center;
	}
}

@media screen and (min-width: 1025px) {
	.rate span {
		position: absolute;
		/* left: 3px; */
		/* margin-left: 10px; */
		margin-bottom: 40px;
		top: 22px;
		font-size: 14px;
		right: 0;
		font-size: 11px;
	}

	.rate input {
		text-align: center;
	}
}

@media screen and (min-width: 993px) and (max-width: 1100px) {
	.refresh {
		position: absolute;
		left: -10px;
		top: 5px;
	}
}

@media screen and (min-width: 1100px) {
	.refresh {
		position: absolute;
		left: -10px;
		top: 5px;
	}
}

@media screen and (min-width: 1200px) {
	.refresh {
		position: absolute;
		left: -12px;
		top: 5px;
	}
}

@media screen and (min-width: 1400px) {
	.refresh {
		position: absolute;
		left: -15px;
		top: 5px;
	}
}

/* @media screen and (min-width: 484px) and (max-width: 576px) {
  .refresh {
    position: absolute;
    left: -18px;
    top: 5px;
  }
} */

/* @media screen and (min-width: 1140px) {
  .refresh {
    position: absolute;
    left: -14px;
    top: 5px;
  }
} */

@media screen and (max-width: 320px) {
	.refresh {
		position: absolute;
		left: -6px;
		top: 5px;
	}
}

/* .select_container .colorContainer{
  margin-left: 4px;
} */

.calender-date {
	font-size: 12px;
}
.due-date {
	font-size: 12px;
}
.payment-mode {
	font-size: 12px;
}
.po-number {
	font-size: 12px;
}
.invoice-date-key {
	font-size: 14px;
}
.invoice-id-key {
	font-size: 14px;
}
.due-date-key {
	font-size: 14px;
}

.payment-mode-key {
	font-size: 14px;
}

.po-number-key {
	font-size: 14px;
}
.desc-input-value {
	font-size: 12px;
}
.taxPercent {
	font-size: 12px;
}
.symbol {
	font-size: 12px;
}

.iscountState {
	font-size: 12px;
}

.countrySymbol {
	font-size: 12px;
}

.balance-value {
	font-size: 12px !important;
}

:-moz-placeholder {
	font-size: 12px;
}

.description-textbox::placeholder {
	font-size: 12px;
	/* margin-top: 14px; */
	display: "flex";
	align-items: center;
	height: 36px;
	/* padding-top: 4px; */
}

.quantity-textbox::placeholder {
	font-size: 12px;
}

.rate-textbox::placeholder {
	font-size: 12px;
}

@media screen and (min-width: 994px) {
	.discount_left_inputTwo {
		position: relative;
	}
	.taxPercent {
		position: absolute;
		top: 20px;
	}
	.symbol {
		position: absolute;
		top: 20px;
	}
}


.newInputPlusIcon{
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
}
