* {
	font-family: "Inter", sans-serif;
}

.navbar {
	box-shadow: 0px 4px 64px rgba(216, 216, 216);
}

.navbar .LogoImg {
	width: 10rem;
}

.RightNavbar a:hover {
	color: #0d6efd;
	border-color: #ffffff;
}

.my__acount {
	width: 146px;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	margin-top: 40px;
	background-color: #2155cd1a;
}

.nav-itemn-account {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	margin-top: 80px;
}

.dash-nav-container {
	width: 123px;
	height: 20px;
	position: absolute;
}

.tooltips {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltips .tooltipstext {
	visibility: hidden;
	width: max-content;
	background-color: white;
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	font-weight: 500;
	position: absolute;
	z-index: 1;
	top: 103%;
	left: 50%;
	margin-left: -52px;
}

.tooltips .tooltipstext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 32%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent white transparent;
}

.tooltips:hover .tooltipstext {
	visibility: visible;
}

.change {
	background-color: #0d6efd;
	color: white;
	border: 1px solid #0d6efd !important;
	transition: 0.2s background-color;
	transition: 0.1s color;
}

@media screen and (max-width: 768px) {
	.my__container {
		display: flex;

		margin-top: 0.3rem;
	}
}

@media screen and (max-width: 320px) {
	.my__container {
		display: flex;
		justify-content: flex-start;
	}

	.my__container button {
		width: 120px !important;
		height: 35px !important;
		font-size: 0.6rem !important;
	}
}

@media screen and (min-width: 376px) and (max-width: 425px) {}

.RightNavbar ul li a:hover,
.RightNavbar ul li a:active,
.RightNavbar ul li a.active {
	color: #95bcf0;
	border: 1px solid #95bcf0;
}

@media screen and (max-width: 755px) {
	.margin-media-query {
		margin-bottom: 1rem;
	}
}

.change:hover {
	background-color: white !important;
	color: #0d6efd !important;
	border: 1px solid #0d6efd !important;
	transition-delay: 0.2s;
}

@media screen and (max-width: 314px) {
	.LogoImg {
		width: 6rem !important;
	}

	.checkkkk {
		height: 0.7rem !important;
		width: 0.7rem !important;
		margin-bottom: 0rem;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

@media screen and (max-width: 320px) {
	.my__container {
		justify-content: space-evenly;
		width: 100%;
		align-items: center;
	}

	.my__account {
		font-size: 0.7rem !important;
		margin-right: 0.4rem;
	}

	.my_account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		list-style: none;
		margin-right: 2px;
	}
}

@media screen and (min-width: 320px) and (max-width: 376px) {
	.my__container {
		justify-content: space-between;
		width: 100%;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		padding-left: 25px !important;
		padding-right: 25px !important;
		list-style: none;
		font-size: 0.7rem;

		text-align: center;
		width: 8rem !important;
	}
}

@media screen and (min-width: 376px) and (max-width: 426px) {
	.my__container {
		justify-content: space-between;
		width: 100%;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		padding-left: 25px !important;
		padding-right: 25px !important;
		list-style: none;
		font-size: 0.8rem !important;
		width: 100%;

		text-align: center;
		width: 9rem !important;
	}
}

@media screen and (min-width: 426px) and (max-width: 540px) {
	.my__container {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		padding-left: 25px !important;
		padding-right: 25px !important;
		list-style: none;
		font-size: 0.8rem !important;
		width: 100%;

		text-align: center;
		width: 9rem !important;
	}
}

@media screen and (min-width: 540px) and (max-width: 765px) {
	.my__container {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		padding-left: 25px !important;
		padding-right: 25px !important;
		list-style: none;
		font-size: 0.8rem !important;
		width: 100%;

		text-align: center;
		width: 9rem !important;
	}
}

@media screen and (min-width: 766px) and (max-width: 768px) {
	.my__container {
		justify-content: space-between;

		font-size: 1rem;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		list-style: none;
		font-size: 1rem;
		width: 100%;
		text-align: center;
		padding: 1rem;
		font-size: 0.7rem;
		height: 1rem;

		width: 9rem !important;
	}

	._account {
		margin-left: 3rem;
	}
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
	.my__container {
		justify-content: space-between;
	}

	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;

		list-style: none;

		text-align: center;
		padding: 1rem;
		font-size: 0.7rem;
		height: 1rem;

		width: 9rem !important;
	}

	._account {
		margin-left: 1rem;
	}

	.nav__element {
		font-size: 0.8rem !important;
	}
}

@media screen and (min-width: 1024px) {
	.my__account {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;

		list-style: none;
		font-size: 0.8rem;

		text-align: center;

		font-size: 0.7rem;
		height: 1rem;
		width: 6.3rem !important;
	}

	._account {
		margin-left: 1rem;
	}

	.nav__element {
		font-size: 0.9rem !important;
	}
}

.account_img {
	font-size: 5rem !important;
}

.checkkkk {
	height: 0.7rem;
	width: 0.7rem;
	margin-bottom: 0.3rem;
	margin-right: 0.2rem;
}

.my__account:hover {
	background-color: white !important;
	color: #95bcf0 !important;
	border: 1px solid #95bcf0 !important;
}