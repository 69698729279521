@media screen and (max-width: 767px) {
	.footer_link {
		font-size: 0.4rem;
	}
}

@media screen and (min-width: 768px) {
	.footer_link {
		font-size: 0.8rem;
	}
}