.flex-wrapper-user-list {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.user-list .heading-text1 {
  color: #2155CD;
  font-weight: 700;
}

.user-list .eye_icon {
  position: absolute !important;
  /* margin-left: 436px;
      margin-top: 10px; */
  right: 15px;
  top: 6px;

}

.user-list .password_input_class {
  position: relative !important;
}

.user-list .close-popup {
  position: relative;
}

.user-list .close-popupbtn {
  position: absolute;
  right: 14px;
  top: -14px;
  font-size: 24px;
}

.user-list .password_input_class {
  position: relative !important;
}

.eye_icon {
  position: absolute !important;
  /* margin-left: 436px;
    margin-top: 10px; */
  right: 15px;
  top: 10px;
}