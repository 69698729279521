.Signup .input-text {
    font-weight: 400;
}

.Signup .head-text {
    font-weight: 700;
}

.Signup .check-box-color {
    color: #36AE7C !important;
}

.Signup .button-effect {
    cursor: pointer;
}

.Signup .button-effect:focus {
    outline: none !important;
    box-shadow: none !important;
}

.Signup .input-box {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 120px;
    background-color: #DDDDDD1A;
    font-weight: 400;

}

.Signup .select-tag-input {

    color: #888888;
    border-color: #DDDDDD;
}

.Signup .select-tag-input:focus {

    box-shadow: none;
}

.Signup .check-box-color:focus {
    box-shadow: none !important;
}


.form-check-input:checked {
    background-color: #36AE7C !important;
    border-color: #36AE7C !important;
}

.Signup input:focus {
    box-shadow: none;
}

.apple {
    color: red;
}

.Signup input:focus-within::after {
    border:red 3px solid !important;
  }
  
  .flex-wrapper-Signup {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .Signup .password_input_class{
position: relative !important;
  }

  .Signup .eye_icon{
    
position: absolute !important;
/* margin-left: 436px;
    margin-top: 10px; */
    right:15px;
    top:10px;
  }