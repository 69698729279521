.four_zero_four_bg {
    height: 100vh;
    width: 100%;
    background-image: url("https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif");
    background-repeat: no-repeat;
    background-position: center;

}

@media (max-width:451px) {
    .pagenotfound {
        height: 90vh;
        background-position: center;

    }
}

@media (max-width:2561px) {
    .pagenotfound {
        height: 90vh;
        background-position: center;

    }
}