.footer {
  display: flex !important;
  justify-content: space-around !important;
}

.footers {
  display: flex !important;
  justify-content: center;
}

* {
  font-family: "Inter", sans-serif;
}

footer .text-clr {
  font-weight: 300;
  color: #888888;
}
footer .text-clr:hover {
  color: #888888;
}

footer {
  position: relative;
  bottom: 0px !important;
}

@media screen and (max-width: 766px) and (min-width: 412px) {
  div.terms-privacy {
    padding-left: 18px;
  }
}
@media screen and (max-width: 425px) {
  div.terms-privacy {
    margin-top: 1rem;
  }
}


/*  */


/* @media screen and (max-width:576px){
  .footer_heading{
     font-size: 0.7rem;;
  }
} */

.footer_heading{
 color:#888888
}

.check{
  display: flex;
  justify-content: flex-end;
 
}

@media screen and (max-width:576px){
  .footer_heading{
    /* color:red; */
    font-size: 0.4rem;

  }
}

@media screen and (min-width:577px) and (max-width:768px){
  .footer_heading{
    /* color:red; */
  }
}


@media screen and  (min-width:768px)and (max-width:992px){
  .footer_heading{
    /* color:red; */
  }
}
@media screen and (min-width:992px)and (max-width:1200px){
  .footer_heading{
    /* color:red; */
  }
}

@media screen and (min-width:1200px){
  .footer_heading{
    /* color:red; */
  }
}