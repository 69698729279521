/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

.history .all{
  /* border: 1px solid rgb(251, 245, 245); */
  /* border-right :3px solid rgb(216, 210, 210); */
  border-radius:12px;
  box-shadow: 0px 10px 64px rgb(204, 200, 200);
}


.history .row {
  background: rgba(226, 229, 248, 0.195);
  box-shadow: 0px 4px 64px rgba(216, 216, 216);
}



.history .empty-message {
  color: #999;
  font-size: 18px;
  font-weight: 200;
  margin: 20px 0;
  text-align: center;
  width: 100%;
}

.history .p-first {
  font-size: 18px;
  font-weight: 300;
}

.history .p-second {
  font-size: 1.2rem;
}

.history .btn-default {
  margin-right: 5px;
  width: 6rem;
}

.history .btn-default {
  background-image: linear-gradient(-180deg, #fff 0, #f9fafb 100%);
  border-color: #c4cdd5;
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 5%);
  color: #313538;
}

.history .btn {
  display: inline-block;
  font-weight: 400;
  font-family: NeueHaasUnica, Helvetica, Arial, sans-serif;
  border-radius: 3px;
  border-width: 1px;
  line-height: 1.5;
}

svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.history .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  margin-right: 3px;
  margin-bottom: 3px;
}

.history .form-control {
  height: 35px;

  /* width:rem; */
}
.history .formgap {
  padding-left: 40px;
}

.history .searchDiv {
  position: relative;
}

        .history .src {
  position: absolute;
  top: 0.4rem;
  left: 1rem;
  width: 1rem;
  width: 1.5rem;
}

.history .form-control:hover {
  border-color: green;
}

@media (min-width: 320px) and (max-width: 767px) {
  .search-btn {
    display: flex;
    flex-direction: column;
   
  }
}

@media (min-width: 500px) and (max-width: 767px) {

  .history .searchDiv {
    width: 60%;
    
  }
}

.history .data {
  /* width:10%; */
  /* height:12%; */
  border: 1px solid #b8c3cf;
  border-radius: 5px;
  position: relative;
  /* margin-top: 10px; */
  display: flex;
  justify-content: center;
  /* width:7rem; */
  overflow: hidden;
  /* position: absolute; */
}

.history .data:hover {
  border: 1px solid #76bcf2;
}

.history .btnchange {
  /* display: flex;
  position: relative;
  float: right; */
  /* color:white;  */
  /* position: absolute; */
  /* margin-left: 8rem; */
  visibility: hidden !important;
}

.history .btncg {
  
  position: relative;
  color: rgba(86, 81, 81, 0.929);
 
}
.history .btncg:hover {
  color: red;
   
}

.history .items {
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  /* position: absolute; */
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  vertical-align: center;

}

.history li {
  width: 6.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.data:hover .btncg{
  visibility: visible;
}

 

.draft{
  font-size: 12px;
  color: #76bcf2;
  position: absolute;
  margin-right: 6.2rem;
  margin-top: 5px;
}

.flex-wrapper-history{
  display: flex;
min-height: 100vh;
flex-direction: column;
justify-content: space-between;
}