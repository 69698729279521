.faq-wrapper{
    padding-left:190px;
    padding-right: 190px;
   
}

.faq-section-outer-container{
 padding-left:40px;
    padding-right: 40px;
}

@media screen and (max-width:768px){
    .faq-wrapper{
    padding-left:10px;
    padding-right:10px;
    margin-left:20px
   
}
.faq-section-outer-container{
 padding-left:35px;
    padding-right: 5px;
}
}
 #accordionExample{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 }

 .accordion-answer{
    text-align: start;
 }

 .accordion-question-key{
    font-weight: bold !important;
 }