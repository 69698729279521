.account-verification-container {
  height:100vh;
}

.parent-div{
  background-color:white;
  height:80vh;
  /* width:100vw;  */
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}

.child-div{
  background-color:white;
  height:60vh;
  width:30vw; 
  margin-top:8rem;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  box-shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius:8px;
}
.right-tick{
  height:40px;
   width: 40px;
  text-align:center;
}

.account-status-heading{
  font-size:20px ;
  color:rgb(49, 123, 49);
  font-weight:bold;
  margin-top:10px;
}

.cross-mark-text{
  color:rgb(163, 39, 39);
}



.account-verification-container{
  /* height:100vh; */
}




/* @media screen and (min-width:320px) and (max-width:480px){ */


  .child-div{
    height:80%;
    width:80%;
    text-align:center;
  }
/* } */

@media screen and (min-width:481px) and (max-width:768px){
  .child-div{
    height:60%;
    width:60%;
    text-align:center;
  }
}

@media screen and (min-width:769px) and (max-width:1024px){
  .child-div{
    height:60%;
    width:60%;
    text-align:center;
  }
}

@media screen and (min-width:1025px) and (max-width:1200px){

}

@media screen and (min-width:1201px){
     
  .child-div{
    height:60%;
    width:40%;
    text-align:center;
  }
}
