.document .table-head {
	border: 1px solid #dddddd !important;
	background: #dddddd1a !important;
	color: #888888;
	font-weight: 400 !important;
}

.document tbody {
	border-top: none !important;
}

.document th {
	font-size: 16px !important;
	font-weight: 400 !important;
}

.sent-text {
	margin-left: 60px;
}

.bg-red {
	background-color: red;
}

.document .active-tab {
	border-bottom: solid blue 1px;
}

.hr-tag {
	margin-top: -17px !important;
}

.notification-number {
	background: #2155cd0d;
	margin-left: 5px;
	color: #2155cd !important;
}

.tab-fonts {
	font-weight: 590;
	cursor: pointer;
}

.below-tab-fonts {
	font-weight: 700;
}

.select-menu-style {
	padding-left: 250px;
	padding-right: 250px;
}

.modal-green-text {
	color: #36ae7c;
	font-weight: 400;
}

.modal-send-document-text {
	font-weight: 600;
}

.modal-who-is-receipent {
	font-weight: 400;
}

.email-address-class {
	font-size: 0.8rem;
	color: #888888 !important;
	font-weight: 400;
}

.which-document-like-send {
	font-weight: 400;
}

.modal-bottom-blue-text {
	color: #2155cd;
	font-weight: 400;
}

.document .modal-header {
	border-bottom: none !important;
}

.document .modal-footer {
	border-top: none !important;
}

.document .modal-radius {
	border-radius: 35px;
}

.table-pending {
	color: #e5a50e;
	background: #e5a50e0d;
	border: 1px solid #e5a50e;
	border-radius: 4px;
}

.document tfoot {
	border-top: none !important;
	border-bottom: none !important;
}

.document tfoot tr {
	border-bottom: none !important;
}

.document tfoot td {
	border-bottom: none !important;
}

.document .logo-size {
	height: 12px;
}

.document thead {
	border-radius: 40px !important;
}

.document .button-focus:focus {
	outline: none !important;
	box-shadow: none !important;
}

.document .cancel-button-modal {
	color: #858585;
	border: none;
}

.document .select-tag-font {
	font-weight: 400 !important;
}

.document .select-border {
	border: 1px solid #dddddd;
	border-radius: 8px;
}

.with {
	margin-top: -31px;
}

.fileinputs {
	position: relative;
}

.fakefile {
	position: absolute;
	z-index: 1;
}

.realfile {
	position: relative;
	opacity: 0;
	z-index: 2;
}

.document .class-downarrow {
	margin-left: 300px;
}

.with1 {
	margin-top: -36px;
	margin-right: 10px;
}

.loader-height {
	height: 50px;
}

.wholetabs {
	display: flex;
	align-items: center;
}

.newCss {
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-modal .modal-header {
	border-bottom: none !important;
}

.modal-dialog-scrollable .modal-content {
	border-radius: 35px !important;
}

.main-modal .modal-green-text {
	margin-bottom: 0px !important;
}

.seeInvoiceClass {
	width: 1.39rem !important;
}

.restorebtn {
	border: 1px solid #0b5ed7;
	border-radius: 6px;
	color: #0b5ed7;
	background-color: white;
	padding: 2px 10px;
}

.restorebtn:hover {
	background-color: #0b5ed7;
	color: white;
}

@media screen and (max-width: 530px) {
	.sent-text {
		margin-left: 1.5rem;
	}
}

.flex-wrapper-documents {
	display: flex;
	/* min-height: 100vh; */
	flex-direction: column;
	justify-content: space-between;
}

@media screen and (max-width: 320px) {
	.tab_name {
		font-size: 0.8rem;
	}

	.document th {
		font-size: 0.67rem !important;
		font-weight: 400 !important;
	}

	.document td {
		font-size: 0.67rem !important;
		font-weight: 400 !important;
	}
}

@media screen and (min-width: 321px) and (max-width: 375px) {
	.tab_name {
		font-size: 0.8rem;
	}

	.tab_name {
		font-size: 0.8rem;
	}

	.document th {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}

	.document td {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}
}

@media screen and (min-width: 376px) and (max-width: 425px) {
	.tab_name {
		font-size: 0.8rem;
	}

	.tab_name {
		font-size: 0.8rem;
	}

	.document th {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}

	.document td {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}
}

@media screen and (min-width: 426px) and (max-width: 768px) {
	.tab_name {
		font-size: 1rem !important;
	}

	.tab_name {
		font-size: 0.8rem;
	}

	.document th {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}

	.document td {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.tab_name {
		font-size: 1rem !important;
	}

	.tab_name {
		font-size: 0.9rem;
	}

	.document th {
		font-size: 0.9rem !important;
		font-weight: 400 !important;
	}

	.document td {
		font-size: 0.9rem !important;
		font-weight: 400 !important;
	}
}

@media screen and (min-width: 1024px) {
	.tab_name {
		font-size: 1rem !important;
	}

	.tab_name {
		font-size: 1rem;
	}

	.document th {
		font-size: 1rem !important;
		font-weight: 400 !important;
	}

	.document td {
		font-size: 0.8rem !important;
		font-weight: 400 !important;
	}
}

.react-datepicker-ignore-onclickoutside {
}
.wrapper-calender-picker {
	width: 120px;
	margin-right: 5px;
}
.search-input-document {
	width: 200px;
	margin-right: 5px;
	border-color: #2155cd;
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 30px;
	border: 1px solid #2155cd;
}
.search-button {
	border-color: #0d6efd;
	border-radius: 8px;
	background-color: #0d6efd;
	color: white;
	border: 1px solid #f0f0f1;
	font-size: 12px;
	width: 90px;
	height: 30px;
	font-weight: 300;
	cursor: pointer;
}

.opacityReduced{
	opacity: 0.4 !important;
	border-color: #0d6efd;
	border-radius: 8px;
	background-color: #0d6efd;
	color: white;
	border: 1px solid #f0f0f1;
	font-size: 12px;
	width: 90px;
	height: 30px;
	font-weight: 300;
	cursor: pointer;
	
}

.react-datepicker-wrapper {
}
.react-datepicker__input-container {
	width: 40px;
}
.custom-input {
	border-radius: 8px;
	padding-left: 8px;
	border-color: #2155cd;
	border: 1px solid #2155cd;
	width: 120px;
	background-color: "green";
}
.react-datepicker {
	margin-top: 30px;
}
.search-main-container {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 14px;
}
.react-datepicker-popper {
	inset: inherit !important;
}

.search-input-document {
	margin-bottom: 14px;
	margin-right: 0px;
}

@media screen and (max-width: 320px) {
	.custom-input {
		width: 80px;
	}
	.search-input-document {
		width: 120px;
	}
	.search-button {
		width: 60px;
	}
	.wrapper-calender-picker {
		width: 80px;

		margin-left: 4px;
	}
	.search-main-container {
		margin-bottom: 10px;
	}
	.search-input-document {
		margin-top: 4px;
		margin-left: 4px;
		width: 130px;
	}
}

@media screen and (min-width: 321px) and (max-width: 375px) {
	.custom-input {
		width: 80px;
	}
	.search-input-document {
		width: 160px;

		margin-top: 4px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.search-button {
		width: 76px;
	}
	.wrapper-calender-picker {
		width: 80px;
	}
	.search-main-container {
		margin-bottom: 10px;
	}
}

@media screen and (min-width: 375px) and (max-width: 425px) {
	.custom-input {
		width: 80px;
	}
	.search-input-document {
		width: 160px;

		margin-top: 4px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.search-button {
		width: 76px;
	}
	.wrapper-calender-picker {
		width: 80px;
	}
	.search-main-container {
		margin-bottom: 10px;
	}
}

