.about-img {
    height: 320px;
    width: 420px;
    border-radius: 6px;
}

@media (max-width:768px) {
    .about-img {
        height: 100%;
        width: 100%;
        border-radius: 6px;
    }
}

@media (max-width:1025px) {
    .about-img {
        height: 100%;
        width: 100%;
        border-radius: 6px;
    }
}

.about-para {
    text-align: justify;
    text-justify: inter-word;
}

.thanks-font {
    font-weight: 500;
}

* {
    font-family: 'Inter', sans-serif;
}

.invoice-guide .heading .heading-text {

    color: #2155CD;
    font-weight: 700;

}

.invoice-guide .heading .heading-text2 {

    color: black !important;
    font-weight: 700;
}

.invoice-guide .heading .button1 {

    color: #FFFFFF;
    font-weight: 400;

}

.invoice-guide .heading .button1:focus {
    outline: none !important;
    box-shadow: none !important;
}

.invoice-guide .heading .extra-font {

    color: black;
    font-weight: 300;
}

.invoice-guide .para-text {

    color: #888888;
    font-weight: 300;

}

.invoice-guide .step-text {

    color: #000000;
    font-weight: 700;

}

.invoice-guide .step-1 {
    margin-top: 3.75rem;
}

.invoice-guide .signup-text {
    font-weight: 700;
    cursor: pointer;
}

.flex-wrapper-invoice-guide {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.terms-font {
    font-size: large;
    color: black;
    font-weight: 300;
}

.privacy-font {
    font-size: large;
    color: black;
    font-weight: 300;
}

.terms-heading {
    padding-top: 18px;
    font-weight: 600;
}

.privacy-heading {
    padding-top: 18px;
    font-weight: 600;
}