*{
    font-family: 'Inter', sans-serif;
}

.container h1, h2, h3, h4{
    font-weight: 700 !important;
}
.container h5{
    font-weight: 700 !important;
}

.heading{
    padding-bottom:20px;
}
.heading p{
    text-align: center;
}
.heading span{
    color: #2155CD;
}
.heading .btnClr{
    background-color: #2155CD;
    color: #ffffff;
}

.heading .btnClr:hover{
    color: #ffffff;
}
.eInvoiceInfo{
    padding-top: 20px;
}
.eInvoiceInfo p{
    color: #888888;
}
.startWithDiv{
  padding-top: 20px;
  padding-bottom: 10px;
}
.sectionPadding{
  padding: 20px 0;
}
.createProfessionalDiv{
  padding-top: 20px;
}
.accessYourDiv{
  padding-top: 20px;
  padding-bottom: 10px;
}

.flex-wrapper-home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

.free-tag ,.for-customer{
    color:#2155CD;
}

.how-does-it-work-img{
    width:100%
}

.invoice-customer-img{
    height: 506px;
    width:100%;
    object-fit: contain;
}


  .custom-invoice-text{
    color:black !important;
    font-weight: bold;
  }

  .customize-feature-text{
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    width:80%;
    margin-bottom:20px;
  }
  
  .bullet-point{
    display: flex;
    width:90%;
  }
  .bullet-point-image{
    height:24px;
    width:24px;
  }
  .each-bullet-point-heading{
   margin-left:15px;  
   width:100%;
  font: normal normal 600 20px/32px Inter;
   text-align: left;  
   font-size: 20px !important;
  }

 

  .invoice-bullet-image{
    height:497px;
    width:98%
  }

 

  .card-streamline{
    border:1px solid #DDDDDD;
 
  }
  .card-strem-img{
    height:51px;
    width:51px;
       margin-top:10px
  }

  .streamline-heading{
    font-size:20px ;
  }

  .streamline-desc{
    font-size: 16px;
  }

  .each-card{
   width: 424px;
    height: 240px;
   padding: 0 5px;
  }
  .main-card-stream {
    margin: 0 -5px;
}

 



   @media screen and (min-width:768px) and (max-width:1024px) {
   
     .each-card{
   /* width:444px; */
    height: 340px;
  }
  .card-streamline{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  }
  @media screen and (max-width:767px){
    .invoice-bullet-image{
    height:307px;
    width:98%
  }
  .bullet-point{
   width:100%;
  }
  .customize-feature-text{
    width:100%;
   
  }
  .invoice-customer-img{
    height: 200px;
  }
  .main-card-stream{
    display: flex;
    align-items: center;
    justify-content: center;
  }
   .each-card{
 /* width:200px; */
  height: unset !important;
}
.pdfImg{
  margin-bottom:20px
}
.generate-unlimited-text-heading{
  margin-top:20px
}
.create-professional-section-container-heading{
  margin-top:20px
}
  }

  
  @media screen and (max-width:425px) {
    .main-card-stream{
      display: flex;
      align-items: center;
      justify-content: center;
    }
     /* .each-card{
   width:120px;
    height: 254px;
    height: unset !important;
  } */
  }
  @media screen and (min-width:1440px) {
     .each-card{
        height: 220px;
     }
  }

  