.container .home {
    color: #585858;
    font-weight: 400;
}

.container .org-name {
    font-weight: 600;
    font-size: x-large;
}

.organization-title {
    color: #888888;
}

.container .welcome-note {
    font-weight: 400;
}

.container .profile-settings {
    cursor: pointer;
}

.container label {
    font-weight: 600;
}

/* .updateBtn{
    position: absolute;
    top: 20rem;
    right: 2rem;
    color:#12151d;
    font-size: .8rem;
    font-weight: 600;
} */

.businessProfile-text {
    font-size: 1.2rem;
}

.received {
    font-size: 1.5rem;
    font-weight: 600;
}

.sent {
    font-size: 1.5rem;
    font-weight: 600;
}

.see-all-btn {
    color: #2155CD;
    cursor: pointer;
}

.link-underline {
    text-decoration: none !important;
}

.flex-wrapper-dashboard {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width:320px) {
    .welcome-note {
        font-size: 1rem !important;
    }

    .my_todo {
        font-size: 0.6rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        /* text-align: center; */
        margin-right: 0.2rem;
    }

    .businessProfile-text {
        font-size: 0.6rem !important;
        text-align: left
    }

    .businessProfile-icon {
        margin-left: 0.5rem;
    }

    .arrow_icon {
        font-size: 1.2rem;
    }

    .my_documents {
        font-size: 0.6rem !important;
    }

    .received_heading,
    .sent_heading {
        font-size: 0.6rem !important;
    }

    .sent,
    .received {
        font-size: 0.5rem !important;
        font-weight: bold;
        text-align: center
    }

    .see_all_container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .link-underline {
        font-size: 0.5rem !important;
        margin-left: 2rem;
        text-align: center;
        /* margin-bottom: 0.5rem;; */
    }

    .welcome-note {
        font-size: 0.9rem !important;
    }

}

@media screen and (min-width:321px) and (max-width:375px) {
    .welcome-note {
        font-size: 1rem !important;
    }

    .my_todo {
        font-size: 0.7rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        /* text-align: center; */
        margin-right: 0.2rem;
        /* color:red !important; */
    }

    .businessProfile-text {
        font-size: 0.7rem !important;
        text-align: left
    }

    .businessProfile-icon {
        margin-left: 0.5rem;
    }

    .arrow_icon {
        font-size: 1.2rem;
    }

    .my_documents {
        font-size: 0.7rem !important;
    }

    .received_heading,
    .sent_heading {
        font-size: 0.7rem !important;
    }

    .sent,
    .received {
        font-size: 0.7rem !important;
        font-weight: bold;
        text-align: center
    }

    .see_all_container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .link-underline {
        font-size: 0.7rem !important;
        margin-left: 2rem;
        text-align: center;
        /* margin-bottom: 0.8rem;; */
    }

    .welcome-note {
        font-size: 0.9rem !important;
    }
}

@media screen and (min-width:376px) and (max-width:425px) {
    .welcome-note {
        font-size: 1rem !important;
    }

    .my_todo {
        font-size: 0.8rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        /* text-align: center; */
        margin-right: 0.2rem;
        /* color:red !important; */
    }

    .businessProfile-text {
        font-size: 0.8rem !important;
        text-align: left
    }

    .businessProfile-icon {
        margin-left: 0.5rem;
    }

    .arrow_icon {
        font-size: 1.2rem;
    }

    .my_documents {
        font-size: 0.8rem !important;
    }

    .received_heading,
    .sent_heading {
        font-size: 0.8rem !important;
    }

    .sent,
    .received {
        font-size: 0.8rem !important;
        font-weight: bold;
        text-align: center
    }

    .see_all_container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .link-underline {
        font-size: 0.8rem !important;
        margin-left: 2.3rem;
        text-align: center;
        /* margin-bottom: 0.8rem;; */
    }

    .welcome-note {
        font-size: 0.9rem !important;
    }
}

@media screen and (min-width:426px) and (max-width:768px) {
    .welcome-note {
        font-size: 1rem !important;
    }

    .my_todo {
        font-size: 0.8rem !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* text-align: center; */
        margin-right: 0.2rem;
        /* color:red !important; */
    }

    .businessProfile-text {
        font-size: 0.8rem !important;
        text-align: left
    }

    .businessProfile-icon {
        margin-left: 0.5rem;
    }

    .arrow_icon {
        font-size: 1.2rem;
    }

    .my_documents {
        font-size: 0.8rem !important;
    }

    .received_heading,
    .sent_heading {
        font-size: 0.8rem !important;
    }

    .sent,
    .received {
        font-size: 0.8rem !important;
        font-weight: bold;
        text-align: center
    }

    .see_all_container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .link-underline {
        font-size: 0.8rem !important;
        margin-left: 2.3rem;
        text-align: center;
        /* margin-bottom:0.46rem;; */
    }

    .welcome-note {
        font-size: 0.9rem !important;
    }

    .receive_send_box {
        margin-top: 0.7rem;
    }
}

@media screen and (min-width:769px) and (max-width:1024px) {
    .welcome-note {
        font-size: 1rem !important;
    }

    .my_todo {
        font-size: 0.8rem !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* text-align: center; */
        margin-right: 0.2rem;
        /* color:red !important; */
    }

    .businessProfile-text {
        font-size: 0.8rem !important;
        text-align: left
    }

    .businessProfile-icon {
        margin-left: 0.5rem;
    }

    .arrow_icon {
        font-size: 1.2rem;
    }

    .my_documents {
        font-size: 0.8rem !important;
    }

    .received_heading,
    .sent_heading {
        font-size: 0.8rem !important;
    }

    .sent,
    .received {
        font-size: 0.8rem !important;
        font-weight: bold;
        text-align: center
    }

    .see_all_container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .link-underline {
        font-size: 0.8rem !important;
        margin-left: 2.3rem;
        text-align: center;
        /* margin-bottom:0.46rem;; */
    }

    .welcome-note {
        font-size: 0.9rem !important;
    }

    .receive_send_box {
        margin-top: 0.7rem;
    }
}

@media screen and (min-width:1025px) {
    .welcome-note {
        font-size: 1rem !important;
    }

    .my_todo {
        font-size: 1rem !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* text-align: center; */
        margin-right: 0.2rem;
        /* color:red !important; */
    }

    .businessProfile-text {
        font-size: 1rem !important;
        text-align: left
    }

    .businessProfile-icon {
        margin-left: 0.5rem;


    }

    .arrow_icon {
        font-size: 2rem !important;
    }

    .my_documents {
        font-size: 1rem !important;
    }

    .received_heading,
    .sent_heading {
        font-size: 0.9rem !important;
    }

    .sent,
    .received {
        font-size: 0.9rem !important;
        font-weight: bold;
        text-align: center
    }

    .see_all_container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .link-underline {
        font-size: 0.99rem !important;
        margin-left: 2.3rem;
        text-align: center;
        /* margin-bottom:0.46rem;; */
    }

    .welcome-note {
        font-size: 0.9rem !important;
    }

    .receive_send_box {
        margin-top: 0.7rem;
        display: flex;
        justify-content: space-between
    }
}